// External packages
import * as React from "react"
import { Box, Paragraph } from "theme-ui"

// Types
import { ContentfulGrid, ProductTile } from "types"

// Components
import { UiGridItem } from "../ui/Contentful"
import { Picture } from "../Picture"
import { Link } from "../Link"

export const Product: React.FC<{
  data: ProductTile
  index: number
  hasStickyTitles: ContentfulGrid["stickyTitles"]
  gridItems: ContentfulGrid["gridItems"]
  mobileLayout: ContentfulGrid["mobileLayout"]
  sideMargins: ContentfulGrid["sideMargins"]
}> = ({
  data,
  index,
  hasStickyTitles,
  gridItems,
  mobileLayout,
  sideMargins,
}) => {
  const image = {
    sizes:
      data?.imagePacks[0]?.featuredImage?.gatsbyImageData?.images?.fallback
        ?.sizes,
    src: data?.imagePacks[0]?.featuredImage?.gatsbyImageData?.images?.fallback
      ?.src,
    srcSet:
      data?.imagePacks[0]?.featuredImage?.gatsbyImageData?.images?.fallback
        ?.srcSet,
    width: data?.imagePacks[0]?.featuredImage?.gatsbyImageData?.width,
    height: data?.imagePacks[0]?.featuredImage?.gatsbyImageData?.height,
  }

  return (
    <UiGridItem
      index={index}
      gridItems={gridItems}
      mobileLayout={mobileLayout}
      sx={{
        "> a": {
          color: "primary",
          textDecoration: "none",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
        },
      }}
    >
      <Link to={`/product/${data?.handle}`}>
        <Box
          data-image
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "4 / 5",
          }}
        >
          {data?.imagePacks[0]?.featuredImage?.gatsbyImageData ? (
            <Picture
              sources={
                data?.imagePacks[0]?.featuredImage?.gatsbyImageData?.images
                  ?.sources
              }
              aspectRatio="vertical"
              sx={{ height: "100%" }}
              imageProps={{
                width: image?.width,
                height: image?.height,
                sizes: image?.sizes,
                src: image?.src,
                srcSet: image?.srcSet,
                alt: data?.title,
              }}
            />
          ) : null}
        </Box>
        <Box
          data-footer
          sx={{
            position: Boolean(data?.title) && hasStickyTitles ? "sticky" : null,
            bottom: Boolean(data?.title) && hasStickyTitles ? 0 : null,
            backgroundColor:
              Boolean(data?.title) && hasStickyTitles ? "grayscale.100" : null,
            paddingBlockStart: 2,
            paddingBlockEnd: [6, 8],
            paddingInline: sideMargins === "0px" ? 4 : 0,
          }}
        >
          {Boolean(data?.title) ? (
            <Paragraph
              sx={{
                fontSize: "sm",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {data?.title}
            </Paragraph>
          ) : null}
        </Box>
      </Link>
    </UiGridItem>
  )
}
