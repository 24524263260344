// External packages
import * as React from "react"
import { Box, Paragraph } from "theme-ui"

// Types
import { ContentfulGrid, ContentfulTileV2 } from "types"

// Utilities
import { getContentfulUrl } from "./utils"

// Components
import { UiGridItem } from "../ui/Contentful"
import { Link } from "../Link"
import { Picture } from "../Picture"

export const TileV2: React.FC<{
  data: ContentfulTileV2
  index: number
  hasStickyTitles: ContentfulGrid["stickyTitles"]
  gridItems: ContentfulGrid["gridItems"]
  mobileLayout: ContentfulGrid["mobileLayout"]
  sideMargins: ContentfulGrid["sideMargins"]
}> = ({
  data,
  index,
  hasStickyTitles,
  gridItems,
  mobileLayout,
  sideMargins,
}) => (
  <UiGridItem index={index} gridItems={gridItems} mobileLayout={mobileLayout}>
    <Link
      to={getContentfulUrl({ linkUrl: data?.urlLink, link: data?.link })}
      sx={{
        color: "primary",
        textDecoration: "none",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
      }}
    >
      <Box
        data-image
        sx={{
          position: "relative",
          width: "100%",
          aspectRatio: "4 / 5",
        }}
      >
        {Boolean(data?.desktopImage) ? (
          data?.desktopImage?.file?.contentType?.startsWith("video") ? (
            <Box
              sx={{
                display: Boolean(data?.mobileImage)
                  ? ["none", "block"]
                  : "block",
                height: "100%",
                video: {
                  display: "block",
                  width: "100%",
                  height: "100%",
                  aspectRatio: "4 / 5",
                  objectFit: "cover",
                  objectPosition: "center",
                },
              }}
            >
              <video
                src={data?.desktopImage?.file?.url}
                autoPlay
                muted={true}
                loop
                playsInline
              >
                <p>Your browser doesn't support HTML video.</p>
              </video>
            </Box>
          ) : (
            <Box
              sx={{
                display: Boolean(data?.mobileImage)
                  ? ["none", "block"]
                  : "block",
                height: "100%",
              }}
            >
              <Picture
                sources={data?.desktopImage?.gatsbyImageData?.images?.sources}
                aspectRatio="vertical"
                sx={{ height: "100%" }}
                imageProps={{
                  width: data?.desktopImage?.gatsbyImageData?.width,
                  height: data?.desktopImage?.gatsbyImageData?.height,
                  sizes:
                    data?.desktopImage?.gatsbyImageData?.images?.fallback
                      ?.sizes,
                  src: data?.desktopImage?.gatsbyImageData?.images?.fallback
                    ?.src,
                  srcSet:
                    data?.desktopImage?.gatsbyImageData?.images?.fallback
                      ?.srcSet,
                  alt: data?.title,
                }}
              />
            </Box>
          )
        ) : null}
        {Boolean(data?.mobileImage) ? (
          data?.mobileImage?.file?.contentType?.startsWith("video") ? (
            <Box
              sx={{
                display: ["block", "none"],
                height: "100%",
                video: {
                  display: "block",
                  width: "100%",
                  height: "100%",
                  aspectRatio: "4 / 5",
                  objectFit: "cover",
                  objectPosition: "center",
                },
              }}
            >
              <video
                src={data?.mobileImage?.file?.url}
                autoPlay
                muted={true}
                loop
                playsInline
              >
                <p>Your browser doesn't support HTML video.</p>
              </video>
            </Box>
          ) : (
            <Box sx={{ display: ["block", "none"] }}>
              <Picture
                sources={data?.mobileImage?.gatsbyImageData?.images?.sources}
                aspectRatio="vertical"
                sx={{ height: "100%" }}
                imageProps={{
                  width: data?.mobileImage?.gatsbyImageData?.width,
                  height: data?.mobileImage?.gatsbyImageData?.height,
                  sizes:
                    data?.mobileImage?.gatsbyImageData?.images?.fallback?.sizes,
                  src: data?.mobileImage?.gatsbyImageData?.images?.fallback
                    ?.src,
                  srcSet:
                    data?.mobileImage?.gatsbyImageData?.images?.fallback
                      ?.srcSet,
                  alt: data?.title,
                }}
              />
            </Box>
          )
        ) : null}
      </Box>
      <Box
        data-footer
        sx={{
          position: Boolean(data?.title) && hasStickyTitles ? "sticky" : null,
          bottom: Boolean(data?.title) && hasStickyTitles ? 0 : null,
          backgroundColor:
            Boolean(data?.title) && hasStickyTitles ? "grayscale.100" : null,
          paddingBlockStart: Boolean(data?.text) ? 3 : 2,
          paddingBlockEnd: [6, 8],
          paddingInline: sideMargins === "0px" ? 4 : 0,
        }}
      >
        {Boolean(data?.title) ? (
          <Paragraph
            sx={{
              fontSize: Boolean(data?.text) ? ["md", "xl"] : "sm",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {data?.title}
          </Paragraph>
        ) : null}
        {Boolean(data?.text) ? (
          <Paragraph sx={{ fontSize: "sm", marginBlockStart: 3 }}>
            {data?.text}
          </Paragraph>
        ) : null}
      </Box>
    </Link>
  </UiGridItem>
)
