// External packages
import * as React from "react"
import { Box, Flex, FlexProps, Heading, Paragraph } from "theme-ui"

// Types
import { ContentfulGrid, ContentfulWidgetDrawer } from "types"

// Hooks
import useLockedBody from "../../../hooks/v2/useLockedBody"

// Components
import { UiGridItem } from "../ui/Contentful"
import { Drawer } from "./Drawer"
import { Button } from "../Button"
import { Picture } from "../Picture"
import { Link } from "../Link"

export const WidgetDrawer: React.FC<{
  data: ContentfulWidgetDrawer
  isInCategoryTemplate?: boolean
  index?: number
  gridItems?: ContentfulGrid["gridItems"]
  mobileLayout?: ContentfulGrid["mobileLayout"]
  hasStickyTitles?: ContentfulGrid["stickyTitles"]
}> = ({
  data,
  isInCategoryTemplate = false,
  index,
  gridItems,
  mobileLayout,
  hasStickyTitles,
}) => {
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false)
  const [locked, setLocked] = useLockedBody(false)

  return (
    <>
      {isInCategoryTemplate && !Boolean(data?.image) ? (
        <WidgetLinkText
          data={data}
          isInCategoryTemplate
          isDrawerOpened={isDrawerOpened}
          setIsDrawerOpened={setIsDrawerOpened}
          locked={locked}
          setLocked={setLocked}
        />
      ) : !isInCategoryTemplate && !Boolean(data?.image) ? (
        <UiGridItem
          index={index}
          gridItems={gridItems}
          mobileLayout={mobileLayout}
        >
          <WidgetLinkText
            data={data}
            isInCategoryTemplate={false}
            isDrawerOpened={isDrawerOpened}
            setIsDrawerOpened={setIsDrawerOpened}
            locked={locked}
            setLocked={setLocked}
          />
        </UiGridItem>
      ) : isInCategoryTemplate && Boolean(data?.image) ? (
        <WidgetLinkImage
          data={data}
          isInCategoryTemplate
          hasStickyTitles={hasStickyTitles}
          setIsDrawerOpened={setIsDrawerOpened}
          setLocked={setLocked}
        />
      ) : !isInCategoryTemplate && Boolean(data?.image) ? (
        <UiGridItem
          index={index}
          gridItems={gridItems}
          mobileLayout={mobileLayout}
        >
          <WidgetLinkImage
            data={data}
            hasStickyTitles={hasStickyTitles}
            setIsDrawerOpened={setIsDrawerOpened}
            setLocked={setLocked}
          />
        </UiGridItem>
      ) : null}
      <Drawer
        data={data.drawerContent}
        isOpened={isDrawerOpened}
        onCloseClick={() => {
          setLocked(false)
          setIsDrawerOpened(false)
        }}
        onSwipeRight={() => {
          setLocked(false)
          setIsDrawerOpened(false)
        }}
        onBackdropClick={() => {
          setLocked(false)
          setIsDrawerOpened(false)
        }}
      />
    </>
  )
}

const WidgetLinkText: React.FC<
  FlexProps & {
    data: ContentfulWidgetDrawer
    isInCategoryTemplate: boolean
    isDrawerOpened: boolean
    setIsDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>
    locked: boolean
    setLocked: (locked: boolean) => void
  }
> = ({
  data,
  isInCategoryTemplate,
  isDrawerOpened,
  setIsDrawerOpened,
  locked,
  setLocked,
  sx,
  ...props
}) => (
  <Flex
    {...props}
    sx={{
      ...sx,
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gridColumn:
        isInCategoryTemplate && data?.size === "2x2" ? "1 / span 2" : null,
      gridRow: isInCategoryTemplate && data?.size === "2x2" ? "span 2" : null,
    }}
    data-textual
  >
    <Heading sx={{ fontSize: "xl" }}>{data?.title}</Heading>
    {Boolean(data?.description) ? (
      <Paragraph sx={{ fontSize: "sm", marginBlockStart: [3, 4] }}>
        {data?.description}
      </Paragraph>
    ) : null}
    <Button
      variant="ghost"
      onClick={() => {
        setLocked(true)
        setIsDrawerOpened(true)
      }}
      sx={{ marginBlockStart: [4, 5] }}
    >
      {data?.buttonLabel}
    </Button>
  </Flex>
)

const WidgetLinkImage = ({
  data,
  isInCategoryTemplate,
  hasStickyTitles,
  setIsDrawerOpened,
  setLocked,
}: {
  data: ContentfulWidgetDrawer
  isInCategoryTemplate?: boolean
  hasStickyTitles?: ContentfulGrid["stickyTitles"]
  setIsDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>
  setLocked: (locked: boolean) => void
}) => (
  <Link
    onClick={() => {
      setLocked(true)
      setIsDrawerOpened(true)
    }}
    sx={{
      gridColumn:
        isInCategoryTemplate && data?.size === "2x2" ? "1 / span 2" : null,
      gridRow: isInCategoryTemplate && data?.size === "2x2" ? "span 2" : null,
    }}
  >
    <Box
      data-image
      sx={{
        position: "relative",
        width: "100%",
        aspectRatio: "4 / 5",
      }}
    >
      <Picture
        sources={data?.image?.gatsbyImageData?.images?.sources}
        sx={{ height: "100%" }}
        imageProps={{
          width: data?.image?.gatsbyImageData?.width,
          height: data?.image?.gatsbyImageData?.height,
          sizes: data?.image?.gatsbyImageData?.images?.fallback?.sizes,
          src: data?.image?.gatsbyImageData?.images?.fallback?.src,
          srcSet: data?.image?.gatsbyImageData?.images?.fallback?.srcSet,
          alt: data?.title,
        }}
      />
    </Box>
    <Box
      data-footer
      sx={{
        position: Boolean(data?.title) && hasStickyTitles ? "sticky" : null,
        bottom: Boolean(data?.title) && hasStickyTitles ? 0 : null,
        backgroundColor:
          Boolean(data?.title) && hasStickyTitles ? "grayscale.100" : null,
        paddingBlockStart: 2,
        paddingBlockEnd: [6, 8],
      }}
    >
      {Boolean(data?.title) ? (
        <Paragraph
          sx={{
            fontSize: "sm",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {data?.title}
        </Paragraph>
      ) : null}
    </Box>
  </Link>
)
