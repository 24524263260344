// External packages
import * as React from "react"
import { createPortal } from "react-dom"
import { Box, BoxProps, Flex, Paragraph } from "theme-ui"
import { useProducts } from "medusa-react"

// Types
import { ContentfulGrid, ContentfulWidgetShopTheLook } from "types"

// Context
import { useStore } from "../../../context/NewStoreContext"
import { useNotificationContext } from "../../../context/NotificationContext"
import { useInterfaceContext } from "../../../context/InterfaceContext"

// Hooks
import useLockedBody from "../../../hooks/v2/useLockedBody"

// Utilities
import { getProductTitleWithColor } from "../../../utils/getProductTitleWithColor"
import { formatProductPrice } from "../../../utils/formatProductPrice"
import { isProductOutOfStock } from "../utils/product/isProductOutOfStock"
import { getVariantsInventory } from "../utils/product/getVariantsInventory"
import { isVariantOutOfStock } from "../utils/product/isVariantOutOfStock"
import { handleAddToCart } from "../utils/product/handleAddToCart"

// Components
import { UiButtonFolded } from "../ui/ButtonFolded"
import { UiGridItem } from "../ui/Contentful"
import { Icon } from "../ui/Icon"
import { Drawer as UiDrawer } from "../ui/Drawer"
import { Picture } from "../Picture"
import { Button } from "../Button"
import { Link } from "../Link"
import { ProductButton } from "../product/ProductButton"
import { SizePickerDrawer } from "../product/SizePickerDrawer"
import { NotifyMeDrawer } from "../product/NotifyMeDrawer"

export const WidgetShopTheLook: React.FC<{
  data: ContentfulWidgetShopTheLook
  isInCategoryTemplate?: boolean
  index?: number
  gridItems?: ContentfulGrid["gridItems"]
  mobileLayout?: ContentfulGrid["mobileLayout"]
  hasStickyTitles?: ContentfulGrid["stickyTitles"]
}> = ({
  data,
  isInCategoryTemplate = false,
  index,
  gridItems,
  mobileLayout,
  hasStickyTitles,
}) =>
  isInCategoryTemplate ? (
    <WidgetShopTheLookContent
      data={data}
      isInCategoryTemplate
      hasStickyTitles={hasStickyTitles}
    />
  ) : (
    <UiGridItem index={index} gridItems={gridItems} mobileLayout={mobileLayout}>
      <WidgetShopTheLookContent data={data} hasStickyTitles={hasStickyTitles} />
    </UiGridItem>
  )

const WidgetShopTheLookContent: React.FC<
  BoxProps & {
    data: ContentfulWidgetShopTheLook
    isInCategoryTemplate?: boolean
    hasStickyTitles?: ContentfulGrid["stickyTitles"]
  }
> = ({ data, isInCategoryTemplate, hasStickyTitles, sx, ...props }) => {
  const [isHovered, setIsHovered] = React.useState(false)
  const [locked, setLocked] = useLockedBody(false)
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false)

  return (
    <>
      <Flex
        {...props}
        sx={{
          ...sx,
          flexDirection: "column",
          position: "relative",
          height: "100%",
          gridColumn:
            isInCategoryTemplate && data?.size === "2x2" ? "1 / span 2" : null,
          gridRow:
            isInCategoryTemplate && data?.size === "2x2" ? "span 2" : null,
          cursor: "pointer",
          marginInline: isInCategoryTemplate &&
            data?.fullWidthOnMobile && [-4, 0],
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          setLocked(true)
          setIsDrawerOpened(true)
        }}
      >
        <Box
          data-image
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            aspectRatio: "4 / 5",
            "> button": {
              height: "100%",
              padding: 0,
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            },
          }}
        >
          {Boolean(data?.image) ? (
            data?.image?.file?.contentType?.startsWith("video") ? (
              <Box
                sx={{
                  height: "100%",
                  video: {
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <video
                  src={data?.image?.file?.url}
                  autoPlay
                  muted={true}
                  loop
                  playsInline
                >
                  <p>Your browser doesn't support HTML video.</p>
                </video>
              </Box>
            ) : (
              <Picture
                sources={data?.image?.gatsbyImageData?.images?.sources}
                aspectRatio="vertical"
                sx={{ height: "100%" }}
                imageProps={{
                  width: data?.image?.gatsbyImageData?.width,
                  height: data?.image?.gatsbyImageData?.height,
                  sizes: data?.image?.gatsbyImageData?.images?.fallback?.sizes,
                  src: data?.image?.gatsbyImageData?.images?.fallback?.src,
                  srcSet:
                    data?.image?.gatsbyImageData?.images?.fallback?.srcSet,
                  alt: data?.titleOrButtonLabel,
                }}
              />
            )
          ) : null}
          {data?.titleOrButton === "Button" ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                padding: 4,
              }}
            >
              <Flex
                sx={{
                  width: "100%",
                  position: "sticky",
                  bottom: 4,
                  justifyContent:
                    data?.buttonAlignment === "Right" ? "end" : "start",
                  marginBlockStart: "auto",
                }}
              >
                {data?.buttonType === "Folded" ? (
                  <UiButtonFolded
                    isFolded={!isHovered}
                    variant={
                      data?.buttonStyle === "Light" ? "secondary" : "primary"
                    }
                  >
                    {data?.titleOrButtonLabel}
                  </UiButtonFolded>
                ) : (
                  <Button
                    variant={
                      data?.buttonStyle === "Light" ? "secondary" : "primary"
                    }
                    iconRight={
                      <Icon
                        name="plus"
                        size="13px"
                        sx={{
                          "svg line": {
                            strokeWidth: "1px",
                          },
                        }}
                      />
                    }
                  >
                    {data?.titleOrButtonLabel}
                  </Button>
                )}
              </Flex>
            </Box>
          ) : null}
        </Box>
        <Box
          data-footer
          sx={{
            position:
              data?.titleOrButton === "Title" &&
              Boolean(data?.titleOrButtonLabel) &&
              hasStickyTitles
                ? "sticky"
                : null,
            bottom:
              Boolean(data?.titleOrButtonLabel) && hasStickyTitles ? 0 : null,
            backgroundColor:
              Boolean(data?.titleOrButtonLabel) && hasStickyTitles
                ? "grayscale.100"
                : null,
            paddingBlockStart: 2,
            paddingBlockEnd: [6, 8],
          }}
        >
          {Boolean(data?.titleOrButton === "Title") && (
            <Paragraph
              sx={{
                fontSize: "sm",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {data?.titleOrButtonLabel}
            </Paragraph>
          )}
        </Box>
      </Flex>
      <Drawer
        products={data?.products}
        isOpened={isDrawerOpened}
        setIsOpened={setIsDrawerOpened}
        setLocked={setLocked}
      />
    </>
  )
}

const Drawer = ({
  products,
  isOpened,
  setIsOpened,
  setLocked,
}: {
  products: ContentfulWidgetShopTheLook["products"]
  isOpened: boolean
  setIsOpened: (value: boolean) => void
  setLocked: (value: boolean) => void
}) => {
  const { cart, createLineItem } = useStore()
  const [selectedVariant, setSelectedVariant] = React.useState(null)
  const [isSizeDrawerOpened, setIsSizeDrawerOpened] = React.useState(false)
  const [isNotifyMeOpen, setIsNotifyMeOpen] = React.useState(false)
  const [openedProduct, setOpenedProduct] = React.useState(products[0])

  const {
    products: MedusaProducts,
    isLoading: MedusaProductLoading,
    isSuccess: MedusaProductsLoaded,
  } = useProducts(
    {
      id: products.map((product) => product.objectId),
      cart_id: cart?.id,
      region_id: cart?.region_id,
      currency_code: cart?.region?.currency_code,
      expand: "color,type,variants,variants.prices,categories",
    },
    {
      enabled: isOpened,
      keepPreviousData: true,
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const productsData = React.useMemo(() => {
    if (!MedusaProducts?.length) {
      return []
    }

    return products?.map((contentfulProduct) => {
      const product = MedusaProducts?.find(
        (p) => contentfulProduct.objectId === p.id
      )

      if (!product?.id) {
        return null
      }

      return {
        id: product?.id,
        title: getProductTitleWithColor(product),
        price: formatProductPrice(product, cart?.region?.currency_code),
        image:
          contentfulProduct?.imagePacks?.[0]?.featuredImage?.gatsbyImageData,
        variants: product.variants,
        disableNotifyMe: contentfulProduct?.disableNotifyMe,
        categories: product.categories,
        type: product.type,
        sku: contentfulProduct?.sku,
        size_guides: contentfulProduct?.size_guides,
        url: `/product/${product.handle}`,
        primaryCategory: product?.categories?.find(
          (i) => i.id === product.primary_category_id
        )?.name,
      }
    })
  }, [products, MedusaProducts])

  const { pushNotification, dismissNotification } = useNotificationContext()
  const { showCart } = useInterfaceContext()

  const onAddToCart = (notificationContent) => {
    pushNotification({
      id: "add-to-cart",
      body: (
        <>
          <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
            Added to cart
          </Paragraph>
          <Paragraph sx={{ marginBlockEnd: 4 }}>
            {notificationContent}
          </Paragraph>
          <Button
            sx={{ width: "100%", marginBlockStart: 6 }}
            onClick={() => {
              dismissNotification("add-to-cart")
              showCart()
            }}
          >
            See the cart
          </Button>
        </>
      ),
      dismiss: {
        duration: 3000,
      },
    })
  }

  const onCloseClick = () => {
    setIsOpened(false)
    setLocked(false)
  }
  const onBackdropClick = () => {
    setIsOpened(false)
    setIsSizeDrawerOpened(false)
    setLocked(false)
  }

  return (
    <>
      <UiDrawer
        isOpened={isOpened}
        onCloseClick={() => onCloseClick()}
        onBackdropClick={() => onBackdropClick()}
        onSwipeRight={() => onCloseClick()}
      >
        <Flex
          sx={{
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
          }}
        >
          {productsData?.map(
            (product) =>
              !isProductOutOfStock(getVariantsInventory(product)) && (
                <Flex
                  key={product?.id}
                  sx={{
                    alignItems: "center",
                    gap: [6, 10],
                    width: "100%",
                    maxWidth: [null, "364px"],
                    ".item-button": {
                      visibility: [null, "hidden"],
                      opacity: [null, 0],
                      transition: "visibility .2s, opacity .2s",
                    },
                    "&:hover .item-button": {
                      visibility: "visible",
                      opacity: 1,
                    },
                    ".item-content": {
                      transform: [null, "translateY(36px)"],
                      transition: "transform .2s",
                    },
                    "&:hover .item-content": {
                      transform: "translateY(0)",
                    },
                  }}
                >
                  {product?.image ? (
                    <Link to={product?.url} sx={{ width: 35, flexShrink: 0 }}>
                      <Picture
                        sources={product?.image?.images.sources}
                        sx={{ flexShrink: 0 }}
                        imageProps={{
                          width: product?.image?.width,
                          height: product?.image?.height,
                          sizes: product?.image?.images.fallback.sizes,
                          src: product?.image?.images.fallback.src,
                          srcSet: product?.image?.images.fallback.srcSet,
                          alt: product?.title,
                        }}
                      />
                    </Link>
                  ) : null}
                  <Flex
                    className="item-content"
                    sx={{
                      flexDirection: "column",
                      flexGrow: 1,
                      gap: 2,
                      fontSize: "sm",
                    }}
                  >
                    <Paragraph
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {product.title}
                    </Paragraph>
                    <Paragraph>{product.price}</Paragraph>
                    <Box sx={{ marginBlockStart: 4 }} className="item-button">
                      {product?.variants.length < 2 ? (
                        <ProductButton
                          sx={{ width: "100%" }}
                          hasNotifyMe={false}
                          isProductSoldOut={
                            isProductOutOfStock(
                              getVariantsInventory(product)
                            ) && Boolean(product?.disableNotifyMe)
                          }
                          isProductOutOfStock={isProductOutOfStock(
                            getVariantsInventory(product)
                          )}
                          isVariantSelected={Boolean(product?.variants[0]?.id)}
                          isVariantOutOfStock={isVariantOutOfStock(
                            product?.variants[0]
                          )}
                          isLoading={
                            createLineItem.isLoading || !MedusaProductsLoaded
                          }
                          isVariantSoldOut={
                            Boolean(product?.disableNotifyMe) &&
                            isVariantOutOfStock(product?.variants[0])
                          }
                          sizePickerVariant="single"
                          isGiftcard={false}
                          isMobile={false}
                          isInDrawer={false}
                          isProductRelated={false}
                          isProductSingleSize={product?.variants?.length < 2}
                          onClick={() => {
                            if (
                              !Boolean(product?.disableNotifyMe) &&
                              isVariantOutOfStock(product?.variants[0])
                            ) {
                              return
                            }

                            if (
                              (isProductOutOfStock(
                                getVariantsInventory(product)
                              ) &&
                                Boolean(product?.disableNotifyMe)) ||
                              (Boolean(product?.disableNotifyMe) &&
                                isVariantOutOfStock(product?.variants[0]))
                            ) {
                              return
                            }

                            onCloseClick()

                            handleAddToCart({
                              product: {
                                title: product.title,
                                subcategory: product.primaryCategory,
                              },
                              variant: {
                                ...product.variants[0],
                                quantity: 1,
                              },
                              cart: cart,
                              createLineItem: createLineItem,
                              onAddToCart: onAddToCart,
                            })
                          }}
                        />
                      ) : (
                        <Button
                          variant="ghost"
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setIsSizeDrawerOpened(true)
                            setOpenedProduct(product)
                          }}
                        >
                          Select size
                        </Button>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              )
          )}
          {typeof window !== "undefined" ? (
            <>
              {createPortal(
                <SizePickerDrawer
                  variants={openedProduct?.variants}
                  product={openedProduct}
                  drawerProps={{
                    isOpened: isSizeDrawerOpened,
                    hasBlur: false,
                    onCloseClick: () => {
                      setIsSizeDrawerOpened(false)
                    },
                    onBackdropClick: () => onBackdropClick(),
                    onSwipeRight: () => {
                      setIsSizeDrawerOpened(false)
                    },
                    sx: { backgroundColor: "grayscale.white" },
                  }}
                  onAddToCart={onAddToCart}
                  onNotifyMeClick={(variant) => {
                    setIsNotifyMeOpen(true)
                    setSelectedVariant(variant)
                    setLocked(true)
                  }}
                  onSizeGuideCloseClick={() => null}
                  onSizeGuideBackdropClick={() => onBackdropClick()}
                />,
                document.getElementById("drawers-root")
              )}
              {createPortal(
                <NotifyMeDrawer
                  isOpened={isNotifyMeOpen}
                  isNotifyOpenedFromDrawer={true}
                  selectedVariant={selectedVariant}
                  onCloseClick={() => setIsNotifyMeOpen(false)}
                  onBackdropClick={() => {
                    setIsNotifyMeOpen(false)
                    onBackdropClick()
                  }}
                />,
                document.getElementById("drawers-root")
              )}
            </>
          ) : null}
        </Flex>
      </UiDrawer>
    </>
  )
}
